import { useState } from "react";
import { useLevelScores, useGetApod, useGetAuth } from "../../hooks";
import Header from "../Header/Header";
import MainPage from "../MainPage/MainPage";
import LandingPage from "../LandingPage/LandingPage";
import StudentsData from "../StudentsData/StudentsData";
import AdminView from "../AdminView/AdminView";
import Popups from "./Popups";

const PageLayout = () => {
  const [
    showSignin,
    setShowSignin,
    email,
    setEmail,
    getAuth,
    verifyUser,
    signInResult,
    setSignInResult,
    message,
    showSpinner,
    setShowSpinner,
    showFlight,
    emailOTPSuccess,
    setEmailOTPSuccess,
    code,
    setCode,
    authData,
    authChecked,
    logout,
    logoutConfirm,
    setLogoutConfirm,
    getAuthToken,
    loggedOut,
    showSchoolReg,
    setShowSchoolReg,
    showAdminView,
    setShowAdminView,
  ] = useGetAuth();
  const [
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    showTabs,
    setShowTabs,
    tabSelect,
    setTabSelect,
    buttonRef,
    currentTab,
    games,
    saveScores,
    saveDataFail,
    setSaveDataFail,
    showStudentsData,
    setShowStudentsData,
  ] = useLevelScores({ authData, getAuthToken, loggedOut });

  const authProps = {
    showSignin,
    setShowSignin,
    email,
    setEmail,
    getAuth,
    verifyUser,
    signInResult,
    setSignInResult,
    message,
    emailOTPSuccess,
    setEmailOTPSuccess,
    code,
    setCode,
  };
  const [apod] = useGetApod();
  const [showTeacherDetails, setShowTeacherDetails] = useState(false);
  const [showStudentDetails, setShowStudentDetails] = useState(false);
  const [gameChangeConfirm, setGameChangeConfirm] = useState(false);
  const [tabClicked, setTabClicked] = useState("");

  const scoreProps = {
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    showTabs,
    setShowTabs,
    tabSelect,
    setTabSelect,
    games,
    saveScores,
    setGameChangeConfirm,
    setTabClicked,
  };

  return (
    <>
      <div className="relative">
        {((tabSelect !== null && showTabs) ||
          showStudentsData ||
          showAdminView) && (
          <Header
            setTabSelect={setTabSelect}
            setShowStudentsData={setShowStudentsData}
            setShowAdminView={setShowAdminView}
          />
        )}
        {tabSelect !== null ? (
          <MainPage
            {...scoreProps}
            setShowSignin={setShowSignin}
            authData={authData}
            authChecked={authChecked}
            setLogoutConfirm={setLogoutConfirm}
          />
        ) : (
          authChecked &&
          (showStudentsData ? (
            <StudentsData getAuthToken={getAuthToken} authData={authData} />
          ) : showAdminView ? (
            <AdminView getAuthToken={getAuthToken} authData={authData} />
          ) : (
            <LandingPage
              setTabSelect={setTabSelect}
              currentTab={currentTab}
              ref={buttonRef}
              apod={apod}
              setShowSignin={setShowSignin}
              authData={authData}
              authChecked={authChecked}
              setLogoutConfirm={setLogoutConfirm}
              setShowStudentsData={setShowStudentsData}
              getAuthToken={getAuthToken}
              setShowSpinner={setShowSpinner}
              showSchoolReg={showSchoolReg}
              setShowSchoolReg={setShowSchoolReg}
              showAdminView={showAdminView}
              setShowAdminView={setShowAdminView}
              setShowTeacherDetails={setShowTeacherDetails}
              setShowStudentDetails={setShowStudentDetails}
            />
          ))
        )}
      </div>
      <Popups
        logoutConfirm={logoutConfirm}
        setLogoutConfirm={setLogoutConfirm}
        logout={logout}
        gameChangeConfirm={gameChangeConfirm}
        setGameChangeConfirm={setGameChangeConfirm}
        setTabSelect={setTabSelect}
        tabClicked={tabClicked}
        games={games}
        showSpinner={showSpinner}
        showFlight={showFlight}
        saveDataFail={saveDataFail}
        setSaveDataFail={setSaveDataFail}
        authProps={authProps}
        showTeacherDetails={showTeacherDetails}
        setShowTeacherDetails={setShowTeacherDetails}
        showStudentDetails={showStudentDetails}
        setShowStudentDetails={setShowStudentDetails}
      />
    </>
  );
};
export default PageLayout;
