import CompositeNode from "../CompositeNode/CompositeNode";
import PartyItems from "../PartyItems/PartyItems";

const GCFImage = ({
  mainNumber,
  mainNumberType,
  numberTree,
  bgColor,
  svgDim,
  checkFactorInput,
  inputChange,
  checkFactor,
  firstTreeComplete,
  item,
}) =>
  mainNumber && (
    <div
      className={`${bgColor} p-1 w-1/2 border border-black overflow-x-scroll overflow-y-scroll flex flex-col place-content-center appear`}
    >
      <div className="w-full h-full flex flex-row place-content-between self-center ">
        <div className="flex flex-col place-content-center self-center w-full h-full">
          <svg
            width={svgDim.width}
            height={svgDim.height}
            xmlns="http://www.w3.org/2000/svg"
            className={`${bgColor} ${
              mainNumberType === "second" && !firstTreeComplete
                ? "opacity-35"
                : "opacity-1"
            }`}
          >
            {numberTree?.length > 0 &&
              numberTree[0].coords?.x &&
              numberTree.map(
                (
                  {
                    number,
                    solved,
                    coords,
                    type,
                    error,
                    factors,
                    row,
                    pos,
                    animate,
                    diamondRef,
                  },
                  index
                ) => (
                  <g key={coords.x}>
                    {type === "p" && (
                      <polyline
                        ref={diamondRef}
                        points={`${coords.x + 35},${coords.y - 20} ${
                          coords.x - 20
                        },${coords.y + 35} ${coords.x + 35},${coords.y + 90} ${
                          coords.x + 90
                        },${coords.y + 35} ${coords.x + 35},${coords.y - 20}`}
                        className="fill-purple-400 stroke-yellow-200 stroke-2 opacity-0"
                      />
                    )}
                    <foreignObject
                      x={coords.x}
                      y={coords.y}
                      width="70"
                      height="70"
                    >
                      <div
                        className={`flex flex-col place-content-center self-center text-center ${
                          type === "p"
                            ? "border-4 border-lime-500"
                            : mainNumberType === "first"
                            ? "border-4 border-blue-600"
                            : "border-4 border-red-600"
                        } w-full h-full rounded-full`}
                      >
                        {solved && (
                          <div
                            className={`flex flex-col place-content-center self-center text-center font-bold w-full h-full rounded-full ${
                              type === "p" ? "bg-yellow-200" : "bg-cyan-200"
                            }`}
                          >
                            <p className="self-center">{number}</p>
                          </div>
                        )}
                      </div>
                    </foreignObject>
                    {index === 0 && (
                      <PartyItems coords={[coords]} items={item} />
                    )}
                    {type === "c" && (
                      <CompositeNode
                        coords={coords}
                        svgDim={svgDim}
                        pos={pos}
                        row={row}
                        animate={animate}
                        factors={factors}
                        checkFactorInput={checkFactorInput}
                        checkFactor={checkFactor}
                        mainNumberType={mainNumberType}
                        inputChange={inputChange}
                        mainNumber={mainNumber}
                        firstTreeComplete={firstTreeComplete}
                      />
                    )}
                  </g>
                )
              )}
          </svg>
        </div>
      </div>
    </div>
  );
export default GCFImage;
