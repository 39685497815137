import FactorButtons from "./FactorButtons";
import GcfCheck from "./GcfCheck";
import EquationHeader from "./EquationHeader";
import ScoreCard from "../../ScoreCard/ScoreCard";
import FactorDiamond from "../FactorDiamond/FactorDiamond";

const GCFEquation = ({
  firstNumber,
  finalFactors,
  secondNumber,
  checkGcf,
  firstTreeComplete,
  secondTreeComplete,
  gcf,
  showSolution,
  solution,
  score,
  solved,
  scorePct,
  checkSolution,
  partyItems,
  gcfRef,
  submitDisabled,
  diamondTextRef1,
  diamondTextRef2,
  diamondTextRef3,
  diamondTextRef4,
  diamondRef1,
  diamondRef2,
}) => (
  <div className="flex flex-row place-content-center p-5 text-base max-[1024px]:text-xs">
    <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black p-2 bg-lime-100 text-center w-full">
      <div className="flex flex-col place-content-center w-full">
        <EquationHeader
          firstNumber={firstNumber}
          secondNumber={secondNumber}
          partyItems={partyItems}
        />
        <div>
          <div className="self-center max-[1024px]:p-0 pl-20 mb-1">
            <ScoreCard
              type="gcf"
              showSolution={showSolution}
              result={"You Got it!"}
              equation={{ problem: "gcf", solution: "solved" }}
              scorePct={scorePct}
              score={score}
              solved={solved}
              setScoreChange={() => {}}
              scoreChange={true}
            />
          </div>
          <div className="flex flex-wrap place-content-evenly">
            {firstNumber && (
              <FactorButtons
                mainNumber={firstNumber}
                factors={finalFactors.firstTree}
                checkGcf={checkGcf}
                type={"firstTree"}
                treeComplete={firstTreeComplete}
                firstTreeComplete={firstTreeComplete}
                secondTreeComplete={secondTreeComplete}
                cn={`border-blue-600 bg-blue-200 hover:bg-slate-100 focus:bg-slate-100`}
              />
            )}
            <GcfCheck
              gcf={gcf}
              showSolution={showSolution}
              checkSolution={checkSolution}
              solution={solution}
              gcfRef={gcfRef}
              submitDisabled={submitDisabled}
            />
            {secondNumber && (
              <FactorButtons
                mainNumber={secondNumber}
                factors={finalFactors.secondTree}
                checkGcf={checkGcf}
                type={"secondTree"}
                treeComplete={secondTreeComplete}
                firstTreeComplete={firstTreeComplete}
                secondTreeComplete={secondTreeComplete}
                cn={
                  "border-red-600 bg-red-200 hover:bg-slate-100 focus:bg-slate-100"
                }
              />
            )}
          </div>
          <div className="flex place-content-center">
            {firstTreeComplete && secondTreeComplete && !showSolution && (
              <FactorDiamond
                diamondRef1={diamondRef1}
                diamondRef2={diamondRef2}
                diamondTextRef1={diamondTextRef1}
                diamondTextRef2={diamondTextRef2}
                diamondTextRef3={diamondTextRef3}
                diamondTextRef4={diamondTextRef4}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GCFEquation;
