import { useState, useEffect, useCallback } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
const keys = require("../keys.js");
const app = initializeApp(keys.firebaseConfig);
const auth = getAuth(app);

const useGetAuth = () => {
  const [showSignin, setShowSignin] = useState(false);
  const [email, setEmail] = useState("");
  const [signInResult, setSignInResult] = useState(false);
  const [message, setMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showFlight, setShowFlight] = useState(false);
  const [emailOTPSuccess, setEmailOTPSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [authData, setAuthData] = useState("");
  const [authChecked, setAuthChecked] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [showSchoolReg, setShowSchoolReg] = useState(false);
  const [showAdminView, setShowAdminView] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(false);

  const resetData = () => {
    setSignInResult(false);
    setMessage("");
    setShowSpinner(true);
    setEmailOTPSuccess(false);
    setCode("");
    setAuthData("");
  };

  const getAuthToken = useCallback(async () => {
    const postData = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      postData.headers.Authorization = `Bearer ${token}`;
    }
    return postData;
  }, []);

  const getAuth = async () => {
    resetData();
    try {
      const postData = await getAuthToken();
      postData.body = JSON.stringify({
        email,
        codeSentDate: new Date().toDateString(),
      });
      const request = new Request(keys.cloudFunctions.createUser, postData);
      const response = await fetch(request);
      const createUserResult = await response.json();

      if (createUserResult?.uid) {
        const request = new Request(keys.cloudFunctions.emailOTP, postData);
        const response = await fetch(request);
        const emailOTPResult = await response.json();
        if (emailOTPResult) setShowSpinner(false);
        if (emailOTPResult?.error) {
          setSignInResult(true);
          setMessage({
            message: (
              <div className="flex flex-wrap place-content-center">
                <div className="self-center text-center">
                  Failed to email one time password to {email}, Send a message
                  to superteachergames@gmail.com if issue persists.
                </div>
              </div>
            ),
            bgColor: "bg-orange-800",
          });
        } else {
          setEmailOTPSuccess(true);
        }
      } else if (createUserResult?.error) {
        setShowSpinner(false);
        setSignInResult(true);
        setMessage({
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                {createUserResult.error.message}
              </div>
            </div>
          ),
          bgColor: "bg-orange-800",
        });
      }
    } catch (error) {
      setSignInResult(true);
      setShowSpinner(false);
      setMessage({
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center text-center">
              Sign in failed at this time!
            </div>
          </div>
        ),
        bgColor: "bg-orange-800",
      });
    }
  };

  const verifyUser = async () => {
    setShowSignin(false);
    setShowSpinner(true);
    try {
      const postData = await getAuthToken();
      postData.body = JSON.stringify({
        email,
        code,
        codeValidatedDate: new Date().toDateString(),
      });
      const request = new Request(keys.cloudFunctions.verifyOTP, postData);
      const response = await fetch(request);
      const verifyCoderResult = await response.json();
      if (verifyCoderResult) setShowSpinner(false);
      if (verifyCoderResult?.token) {
        setShowSignin(false);
        setEmailOTPSuccess(false);
        const userCreds = await signInWithCustomToken(
          auth,
          verifyCoderResult.token
        );
        setAuthData({
          authUserId: verifyCoderResult.uid,
          email: userCreds.user.uid,
          role: verifyCoderResult?.role,
          admin: verifyCoderResult?.admin,
        });
        setSignInResult(true);
        setMessage({
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="text-black self-center text-center">
                You are signed in with "{userCreds.user.uid}, You can register
                your school by clicking on top right corner."
              </div>
            </div>
          ),
          bgColor: "bg-[#00c6fc]",
        });
        setLoggedOut(false);
      } else if (verifyCoderResult?.error.message) {
        setSignInResult(true);
        setMessage({
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                {verifyCoderResult?.error.message}
              </div>
            </div>
          ),
          bgColor: "bg-orange-800",
        });
      } else if (verifyCoderResult?.error.message === "Code not valid") {
        setSignInResult(true);
        setMessage({
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                Code {code} provided not valid, check the email & code provided.
              </div>
            </div>
          ),
          bgColor: "bg-orange-800",
        });
      }
    } catch (error) {
      setSignInResult(true);
      setShowSpinner(false);
      setMessage({
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center text-center">
              Code Verification failed!
            </div>
          </div>
        ),
        bgColor: "bg-orange-800",
      });
    }
  };

  const logout = async () => {
    try {
      await auth.authStateReady();
      await signOut(auth);
      resetData();
      setShowSpinner(false);
      setLoggedOut(true);
    } catch (error) {
      setShowSpinner(false);
      setSignInResult(true);
      setMessage({
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center text-center">
              Logout failed at this time!
            </div>
          </div>
        ),
        bgColor: "bg-orange-800",
      });
    }
  };

  useEffect(() => {
    const start = Date.now();
    const timerId = setTimeout(() => setTimeElapsed(Date.now() - start), 7000);
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setShowFlight(true);
        await auth.authStateReady();
        if (auth.currentUser) {
          const postData = await getAuthToken();
          postData.body = JSON.stringify({ email: auth.currentUser.uid });
          const request = new Request(keys.cloudFunctions.checkUser, postData);
          const response = await fetch(request);
          const user = await response.json();
          setAuthData({
            authUserId: user.uid,
            email: user.email,
            role: user?.role || "",
            admin: user?.admin || "",
          });
          if (timeElapsed) {
            setSignInResult(true);
            setMessage({
              message: (
                <div className="flex flex-wrap place-content-center">
                  <div className="text-black self-center text-center">
                    You are signed in with "{user.email}"
                  </div>
                </div>
              ),
              bgColor: "bg-[#00c6fc]",
            });
          }
        } else {
          if (timeElapsed) {
            setSignInResult(true);
            setMessage({
              message: (
                <div className="border p-5 flex flex-wrap place-content-center">
                  <div className="self-center text-center">
                    <p className="text-center text-lg max-[1024px]:text-sm text-stone-600">
                      Sign in option, top right corner.
                    </p>
                    <ul className="list-disc text-left text-blue-900 p-2 font-bold">
                      <li className="p-2">
                        Once signed in, teachers can register their school to
                        track their students’ performance.
                      </li>
                      <li className="p-2">
                        Students can link up to their school.
                      </li>
                    </ul>
                    <p className="text-center text-lg max-[1024px]:text-sm text-stone-600">
                      See more details by clicking top right corner.
                    </p>
                  </div>
                </div>
              ),
              bgColor: "bg-green-100 border-4 border-double border-stone-600",
            });
          }
        }
        if (timeElapsed) {
          setAuthChecked(true);
          setShowFlight(false);
        }
      } catch (error) {
        if (timeElapsed) {
          setAuthChecked(true);
          setShowFlight(false);
          setSignInResult(true);
          setMessage({
            message: (
              <div className="flex flex-wrap place-content-center">
                <div className="self-center text-center">
                  You're session has ended, sign in to be able to save you
                  scores.
                </div>
              </div>
            ),
            bgColor: "bg-orange-400",
          });
        }
      }
    })();
  }, [getAuthToken, timeElapsed]);

  return [
    showSignin,
    setShowSignin,
    email,
    setEmail,
    getAuth,
    verifyUser,
    signInResult,
    setSignInResult,
    message,
    showSpinner,
    setShowSpinner,
    showFlight,
    emailOTPSuccess,
    setEmailOTPSuccess,
    code,
    setCode,
    authData,
    authChecked,
    logout,
    logoutConfirm,
    setLogoutConfirm,
    getAuthToken,
    loggedOut,
    showSchoolReg,
    setShowSchoolReg,
    showAdminView,
    setShowAdminView,
  ];
};

export default useGetAuth;
