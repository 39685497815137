import { useState, useEffect } from "react";
import orderBy from "lodash.orderby";
import gameLabels from "../icons/gameLabels";
const keys = require("../keys.js");

const useGetAdminsData = ({ getAuthToken, authData }) => {
  const [userData, setUserData] = useState([]);
  const [details, setDetails] = useState([]);
  const [detailsUser, setDetailsUser] = useState("");
  const [nullDateAlerts, setNullDateAlerts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [filterSchool, setFilterSchool] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [userDataFiltered, setUserDataFiltered] = useState([]);
  const [schoolNames, setSchoolNames] = useState([]);

  const getGameDetails = ({ user }) => {
    setDetails([]);
    setDetailsUser(user);
    let data = userData.filter((ud) => ud.user === user)[0].games;
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        gameLabels.forEach((gl) => {
          if (gl.keyName === key) {
            const dp = { type: gl.label, datesPlayed: [] };
            for (const [key2] of Object.entries(value.datesPlayed)) {
              const dt =
                key2 !== "null"
                  ? new Date(key2 * 1000)
                      .toDateString("default", {
                        month: "long",
                      })
                      .substring(4)
                  : key2;
              dp.datesPlayed = [...dp.datesPlayed, dt];
            }
            setDetails((details) => [...details, dp]);
          }
        });
      }
    } else {
      setDetails([{ type: "None Played" }]);
    }
  };

  useEffect(() => {
    (async () => {
      setNullDateAlerts([]);
      setShowLoader(true);

      if (authData?.admin) {
        try {
          const postData = await getAuthToken();
          postData.body = JSON.stringify({
            email: authData.email,
          });
          const request = new Request(
            keys.cloudFunctions.getDataForAdmins,
            postData
          );
          const response = await fetch(request);
          const adminData = await response.json();
          adminData?.usersData.map(
            (a) => (a.userLowerCase = a.user.toLowerCase())
          );
          let userSorted = orderBy(
            [...adminData?.usersData],
            ["userLowerCase"],
            ["asc"]
          );

          userSorted = orderBy([...userSorted], ["registered"], ["desc"]);

          setUserData(userSorted);
          /******** Null date check */
          adminData?.usersData.forEach((ud) => {
            const data = ud?.games;
            if (data) {
              for (const [key, value] of Object.entries(data)) {
                gameLabels.forEach((gl) => {
                  if (gl.keyName === key) {
                    for (const [key2] of Object.entries(value.datesPlayed)) {
                      if (key2 === "null")
                        setNullDateAlerts((nullDateAlerts) => [
                          ...nullDateAlerts,
                          { user: ud.user, game: gl.label },
                        ]);
                    }
                  }
                });
              }
            }
          });
          setShowLoader(false);
        } catch (error) {
          setShowLoader(false);
        }
      }
    })();
  }, [getAuthToken, authData?.admin, authData.email]);

  useEffect(() => {
    resetDetails();
    if (filterSchool === "N/A" || filterRole === "N/A") {
      if (
        (filterSchool === "N/A" && !filterRole) ||
        (!filterSchool && filterRole === "N/A") ||
        (filterSchool === "N/A" && filterRole === "N/A")
      )
        setUserDataFiltered(userData.filter((u) => !u?.role));
      else setUserDataFiltered([]);
    } else if (filterSchool && filterRole) {
      setUserDataFiltered(
        userData.filter(
          (u) =>
            u?.role?.school === filterSchool && u?.role?.type === filterRole
        )
      );
    } else if (filterSchool) {
      setUserDataFiltered(
        userData.filter((u) => u?.role?.school === filterSchool)
      );
    } else if (filterRole) {
      setUserDataFiltered(userData.filter((u) => u?.role?.type === filterRole));
    } else {
      setUserDataFiltered([...userData]);
    }
  }, [filterSchool, filterRole, userData]);

  useEffect(() => {
    const getSchoolsList = async () => {
      try {
        const postData = await getAuthToken();
        postData.body = JSON.stringify({
          email: authData.email,
        });
        const request = new Request(keys.cloudFunctions.getSchools, postData);
        const response = await fetch(request);
        const getSchoolsResponse = await response.json();
        const ordered = orderBy(getSchoolsResponse?.schools);
        setSchoolNames(ordered);
      } catch (error) {}
    };
    getSchoolsList();
  }, [getAuthToken, authData.email]);

  const resetDetails = () => {
    setDetails([]);
    setDetailsUser("");
  };

  return [
    getGameDetails,
    details,
    detailsUser,
    nullDateAlerts,
    showLoader,
    setFilterSchool,
    setFilterRole,
    userDataFiltered,
    schoolNames,
    resetDetails,
  ];
};
export default useGetAdminsData;
