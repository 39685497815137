const FactorDiamond = ({
  diamondRef1,
  diamondRef2,
  diamondTextRef1,
  diamondTextRef2,
  diamondTextRef3,
  diamondTextRef4,
}) => {
  return (
    <svg
      width="320"
      height="160"
      xmlns="http://www.w3.org/2000/svg"
      className={`appear self-center flex place-content-center`}
    >
      <polyline
        ref={diamondRef1}
        points={`${80},${0} ${0},${80} ${80},${160} ${160},${80} ${80},${0}`}
        className="fill-purple-400/50 stroke-yellow-200 stroke-2"
      />
      <polyline
        ref={diamondRef2}
        points={`${240},${0} ${160},${80} ${240},${160} ${320},${80} ${240},${0}`}
        className="fill-purple-400/50 stroke-yellow-200 stroke-2"
      />
      <foreignObject
        ref={diamondTextRef1}
        x="60"
        y="60"
        width="40"
        height="40"
        className="place-content-center text-center"
      />
      <text
        ref={diamondTextRef3}
        x="70"
        y="85"
        className="place-content-center text-center"
      ></text>
      <text
        ref={diamondTextRef4}
        x="230"
        y="85"
        className="place-content-center text-center"
      ></text>
      <foreignObject
        ref={diamondTextRef2}
        x="220"
        y="60"
        width="40"
        height="40"
        className="place-content-center text-center"
      />
    </svg>
  );
};
export default FactorDiamond;
