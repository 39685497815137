import { useState, useEffect, useCallback, useRef, createRef } from "react";
import orderBy from "lodash.orderby";

const useGetGcfEquation = ({
  width,
  height,
  challengeLevel,
  score,
  setScore,
  solved,
  setSolved,
  saveScores,
}) => {
  const levelRef = useRef(null);
  let diamondTextRef1 = useRef(null);
  let diamondTextRef2 = useRef(null);
  let diamondTextRef3 = useRef(null);
  let diamondTextRef4 = useRef(null);
  let diamondRef1 = useRef(null);
  let diamondRef2 = useRef(null);
  let gcfRef = useRef(null);
  const [next, setNext] = useState(false);
  const [firstNumber, setFirstNumber] = useState("");
  const [firstMaxFactors, setFirstMaxFactors] = useState([]);
  const [firstNumberTree, setFirstNumberTree] = useState([]);
  const [secondNumber, setSecondNumber] = useState("");
  const [secondMaxFactors, setSecondMaxFactors] = useState([]);
  const [secondNumberTree, setSecondNumberTree] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [message, setMessage] = useState("");
  const [firstSvgDim, setFirstSvgDim] = useState({ width: 0, height: 0 });
  const [secondSvgDim, setSecondSvgDim] = useState({ width: 0, height: 0 });
  const [firstMaxRows, setFirstMaxRows] = useState(0);
  const [secondMaxRows, setSecondMaxRows] = useState(0);
  const [currentNode, setCurrentNode] = useState({ row: 0, pos: 1 });
  const [finalFactors, setFinalFactors] = useState({
    firstTree: [],
    secondTree: [],
  });
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [firstTreeComplete, setFirstTreeComplete] = useState(false);
  const [secondTreeComplete, setSecondTreeComplete] = useState(false);
  const [gcf, setGcf] = useState([]);
  const [solution, setSolution] = useState("");
  const [showSolution, setShowSolution] = useState(false);
  const [showConfettiEmo, setShowConfettiEmo] = useState(false);
  const [partyItems, setPartyItems] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [dataNode] = useState({
    coords: {},
    solved: true,
    row: 0,
    pos: 1,
    animate: true,
    type: "c",
    error: false,
    factors: {
      factor1: {
        number: "",
        error: false,
        showBtn: false,
        solved: false,
        type: "",
      },
      factor2: {
        number: "",
        error: false,
        showBtn: false,
        solved: false,
        type: "",
      },
    },
  });
  const [timerId1, setTimerId1] = useState("");
  const [timerId2, setTimerId2] = useState("");
  const [timerId3, setTimerId3] = useState("");
  const [timerId4, setTimerId4] = useState("");
  const [timerId5, setTimerId5] = useState("");
  const [timerId6, setTimerId6] = useState("");
  const [timerId7, setTimerId7] = useState("");
  const [timerId8, setTimerId8] = useState("");
  const [timerId9, setTimerId9] = useState("");
  const [timerId10, setTimerId10] = useState("");

  const handleNext = useCallback(() => {
    setNext(true);
    setFirstNumber("");
    setFirstMaxFactors([]);
    setFirstNumberTree([]);
    setSecondNumber("");
    setSecondMaxFactors([]);
    setSecondNumberTree([]);
    setErrorModal(false);
    setMessage("");
    setFirstSvgDim({ width: 0, height: 0 });
    setSecondSvgDim({ width: 0, height: 0 });
    setFirstMaxRows(0);
    setSecondMaxRows(0);
    setFirstTreeComplete(false);
    setSecondTreeComplete(false);
    setShowInstructions(false);
    setFinalFactors({
      firstTree: [],
      secondTree: [],
    });
    setGcf([]);
    setSolution("");
    setShowSolution(false);
    setCurrentNode({ row: 0, pos: 1 });
    setSubmitDisabled(true);
  }, []);

  const createTemplate = useCallback(() => {
    const dn = JSON.parse(JSON.stringify(dataNode));
    dn.factors.factor1 = {
      ...dn.factors.factor1,
      inputRef: createRef(),
    };
    dn.factors.factor2 = {
      ...dn.factors.factor2,
      inputRef: createRef(),
    };
    return dn;
  }, [dataNode]);

  const getNumber = useCallback(({ max, min }) => {
    let fn = 0;
    while (fn === 0) {
      let n = Math.floor(Math.random() * (max - min) + min);
      if (!checkPrime(n)) {
        fn = n;
        return n;
      }
    }
  }, []);

  const inputChange = ({ event, row, type, parentCoords, mainNumberType }) => {
    setCurrentNode(null);
    let temp =
      mainNumberType === "first" ? [...firstNumberTree] : [...secondNumberTree];
    temp.map((t) => {
      if (
        t.row === row &&
        t.coords.x === parentCoords.x &&
        t.coords.y === parentCoords.y
      ) {
        t.factors[type].number = event.target.value;
        t.factors[type].error = false;
      }
      return t;
    });
    mainNumberType === "first"
      ? setFirstNumberTree(temp)
      : setSecondNumberTree(temp);
  };

  const checkPrime = (n) => {
    const middle = Math.sqrt(n);
    let i = 2;
    while (i <= middle) {
      if (n % i === 0) return false;
      i++;
    }
    return true;
  };

  const checkFactorInput = ({
    event,
    row,
    type,
    otherFactor,
    coords,
    parentCoords,
    mainNumberType,
  }) => {
    let temp =
      mainNumberType === "first" ? [...firstNumberTree] : [...secondNumberTree];
    temp.map((t) => {
      if (
        t.row === row &&
        t.coords.x === parentCoords.x &&
        t.coords.y === parentCoords.y
      ) {
        const f = parseFloat(t.factors[type].number);
        let msg = "";
        setCurrentNode({ row: t.row, pos: t.pos });

        if (!Number.isInteger(f)) {
          t.factors[type].error = true;
          msg = "Has to be an integer.";
        } else if (f === t.number) {
          t.factors[type].error = true;
          msg = "Cannot be same as the number!";
        } else if (
          (t.factors[otherFactor].number &&
            (t.factors[otherFactor].solved || t.factors[otherFactor].showBtn) &&
            parseFloat(t.factors[otherFactor].number) * f !== t.number) ||
          t.number % f !== 0
        ) {
          t.factors[type].error = true;
          msg = `${f} is not the correct factor!`;
        } else if (t.number % f === 0) {
          t.factors[type].showBtn = true;
        }
        if (msg) {
          setErrorModal(true);
          setMessage({
            message: (
              <div className="flex flex-wrap place-content-center">
                <div className="self-center text-center">{msg}</div>
              </div>
            ),
            bgColor: "bg-yellow-600",
          });
        }
      }
      return t;
    });
    mainNumberType === "first"
      ? setFirstNumberTree(temp)
      : setSecondNumberTree(temp);
  };

  const checkFactor = ({
    number,
    row,
    type,
    btnType,
    coords,
    parentCoords,
    mainNumberType,
  }) => {
    let temp =
      mainNumberType === "first" ? [...firstNumberTree] : [...secondNumberTree];
    let newNode = "";

    if (btnType === "p") {
      temp.map((t) => {
        if (
          t.row === row &&
          t.coords.x === parentCoords.x &&
          t.coords.y === parentCoords.y
        ) {
          if (checkPrime(number)) {
            t.factors[type].showBtn = false;
            t.factors[type].solved = true;
            t.factors[type].type = "p";
            const fnt = { ...finalFactors };
            const diamondRef = createRef();
            if (mainNumberType === "first") {
              fnt.firstTree = [
                ...finalFactors.firstTree,
                {
                  number: parseFloat(number),
                  matched: false,
                  picked: false,
                  ref: createRef(),
                  disable: true,
                  diamondRef,
                },
              ];
              setFinalFactors(fnt);
            } else {
              fnt.secondTree = [
                ...finalFactors.secondTree,
                {
                  number: parseFloat(number),
                  matched: false,
                  picked: false,
                  ref: createRef(),
                  disable: true,
                  diamondRef,
                },
              ];
              setFinalFactors(fnt);
            }
            const dn = createTemplate();
            newNode = {
              ...dn,
              number: parseFloat(number),
              coords,
              row: t.row + 1,
              pos:
                type === "factor1" ? t.pos + (t.pos - 1) : t.pos + (t.pos + 1),
              type: "p",
              parentCoords,
              diamondRef,
            };
          } else {
            setErrorModal(true);
            setMessage({
              message: (
                <div className="flex flex-wrap place-content-center">
                  <div className="self-center text-center">
                    Not a prime number.
                  </div>
                </div>
              ),
              bgColor: "bg-yellow-600",
            });
          }
        }
        return t;
      });
    } else {
      temp.map((t) => {
        if (
          t.row === row &&
          t.coords.x === parentCoords.x &&
          t.coords.y === parentCoords.y
        ) {
          if (!checkPrime(number)) {
            t.factors[type].showBtn = false;
            t.factors[type].solved = true;
            t.factors[type].type = "c";
            const dn = createTemplate();
            newNode = {
              ...dn,
              number: parseFloat(number),
              coords,
              row: t.row + 1,
              pos:
                type === "factor1" ? t.pos + (t.pos - 1) : t.pos + (t.pos + 1),
              type: "c",
              parentCoords,
            };
          } else {
            setErrorModal(true);
            setMessage({
              message: (
                <div className="flex flex-wrap place-content-center">
                  <div className="self-center text-center">
                    Not a composite number.
                  </div>
                </div>
              ),
              bgColor: "bg-yellow-600",
            });
          }
        }
        return t;
      });
    }

    if (newNode) {
      temp.push(newNode);
      const notPrime = !checkPrime(newNode.number);
      notPrime && setCurrentNode({ row: newNode.row, pos: newNode.pos });
      if (mainNumberType === "first") {
        notPrime && newNode.row > firstMaxRows && setFirstMaxRows(newNode.row);
        setFirstNumberTree(temp);
      } else {
        notPrime &&
          newNode.row > secondMaxRows &&
          setSecondMaxRows(newNode.row);
        setSecondNumberTree(temp);
      }
    }
  };

  const findMaxFactors = useCallback((n) => {
    let i = 2;
    let temp = [];
    while (!checkPrime(n)) {
      if (n % i === 0) {
        if (checkPrime(i)) {
          temp.push(i);
          n = n / i;
          if (checkPrime(n)) temp.push(n);
        }
      } else {
        i = i + 1;
      }
    }
    return temp;
  }, []);

  const resetAnimate = ({ numberTreeType }) =>
    numberTreeType.map((f) => {
      if (f.animate) setTimerId8(setTimeout(() => (f.animate = false), [1100]));
      return f;
    });

  const growSvg = ({ maxFactors, numOfRows, setSvgDim, width, height }) => {
    let maxW = Math.pow(2, maxFactors.length - 1) * 120;
    const maxH = 230 * (maxFactors.length / 2) + 20;
    maxW = maxW < width / 2 ? width / 2 : maxW;
    const newW = Math.pow(2, numOfRows + 1) * 120;
    const w = newW > maxW ? maxW : newW;
    setSvgDim((svgDim) => {
      let s = {
        width: svgDim.width,
        height: maxH,
      };
      if (svgDim.width < maxW) {
        s = {
          width: w < width / 2 ? width / 2 : w,
          height: maxH,
        };
      }
      return s;
    });
  };

  const moveFocus = ({ numberTree, refLocation }) => {
    setTimerId9(
      setTimeout(() => {
        numberTree.forEach((fnt) => {
          if (fnt.row === refLocation.row && fnt.pos === refLocation.pos) {
            if (!fnt.factors.factor1.number) {
              fnt.factors.factor1.inputRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              fnt.factors.factor1.inputRef?.current?.focus();
            } else if (
              !fnt.factors.factor2.number &&
              fnt.factors.factor1.showBtn
            ) {
              fnt.factors.factor2.inputRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              fnt.factors.factor2.inputRef?.current?.focus();
            }
          }
        });
      }, [500])
    );
  };

  const assignCoords = ({ setNumberTree, svgDim }) => {
    setNumberTree((fnt) =>
      fnt.map((f, index) => {
        f.coords = {
          x: svgDim.width * (f.pos / Math.pow(2, f.row + 1)) - 35,
          y: f.row * 100,
        };
        return f;
      })
    );
  };
  const getX = (ref) => ref.getBoundingClientRect().right;
  const getY = (ref) => ref.getBoundingClientRect().top;
  const nodeTransform = ({ distance }) => {
    diamondRef1.current.style.transform = `translate(${distance}px,${0}px)`;
    diamondRef1.current.style.transition = "transform 1s";
    diamondRef2.current.style.transform = `translate(${-distance}px,${0}px)`;
    diamondRef2.current.style.transition = "transform 1s";
    diamondTextRef3.current.style.transform = `translate(${distance}px,${0}px)`;
    diamondTextRef3.current.style.transition = "transform 1s";
    diamondTextRef4.current.style.transform = `translate(${-distance}px,${0}px)`;
    diamondTextRef4.current.style.transition = "transform 1s";
  };
  const emptyTextNode = () => {
    diamondTextRef1.current.innerHTML = "";
    diamondTextRef2.current.innerHTML = "";
    diamondTextRef3.current.innerHTML = "";
    diamondTextRef4.current.innerHTML = "";
  };
  const factorButtonsEvent = ({ single, type, add }) => {
    if (single) {
      document
        .getElementById(`${type}FactorButtons`)
        .classList.add("pointer-events-none");
    } else {
      if (add) {
        document
          .getElementById("firstTreeFactorButtons")
          .classList.add("pointer-events-none");
        document
          .getElementById("secondTreeFactorButtons")
          .classList.add("pointer-events-none");
      } else {
        document
          .getElementById("firstTreeFactorButtons")
          .classList.remove("pointer-events-none");
        document
          .getElementById("secondTreeFactorButtons")
          .classList.remove("pointer-events-none");
      }
    }
    setFinalFactors((finalFactors) => {
      for (const [key] of Object.entries(finalFactors)) {
        finalFactors[key].map((f) => {
          f.ref.current.tabIndex = single
            ? key === type
              ? "-1"
              : "0"
            : add
            ? "-1"
            : "0";
          return f;
        });
      }
      return { ...finalFactors };
    });
  };
  const flyingNode = ({ startRef, stopRef, stopRef2, number }) => {
    startRef.style.position = "relative";
    let movingNode = document.createElement("div");
    movingNode.appendChild(document.createTextNode(number));
    movingNode.style.position = "absolute";
    movingNode.style.top = "0";
    movingNode.style.left = "0";
    movingNode.style.right = "0";
    movingNode.style.bottom = "0";
    movingNode.style.fontWeight = "bold";
    movingNode.style.fontSize = "32px";
    movingNode.style.color = "purple";
    startRef.appendChild(movingNode);
    let x = getX(stopRef) - getX(startRef);
    let y = getY(stopRef) - getY(startRef);
    movingNode.style.transform = `translate(${0}px,${0}px)`;
    setTimerId6(
      setTimeout(() => {
        movingNode.style.transform = `translate(${x}px,${y}px)`;
        movingNode.style.transition = "transform 1s";
        setTimerId7(
          setTimeout(() => {
            startRef.removeChild(movingNode);
          }, 1000)
        );
      }, 200)
    );

    setTimerId1(
      setTimeout(() => {
        stopRef.style.position = "relative";
        stopRef2.appendChild(document.createTextNode(number));
        stopRef2.style.fontWeight = "bold";
        stopRef2.style.fontSize = "32px";
        stopRef2.style.fill = "purple";

        const n1 = parseFloat(diamondTextRef3.current.textContent);
        const n2 = parseFloat(diamondTextRef4.current.textContent);

        if (Number.isInteger(n1) && Number.isInteger(n2)) {
          if (n1 !== n2) {
            emptyTextNode();
            factorButtonsEvent({ single: false, type: "", add: false });
          } else {
            factorButtonsEvent({ single: false, type: "", add: true });
            setTimerId2(
              setTimeout(() => {
                nodeTransform({ distance: 80 });
                setTimerId3(
                  setTimeout(() => {
                    const factor = gcf.filter(
                      (g) => g.number === number && !g.show
                    )[0];
                    setGcf((gcf) => {
                      gcf.map((g) => {
                        if (factor.ref.current === g.ref.current) {
                          g.show = true;
                        }
                        return g;
                      });
                      return gcf;
                    });
                    let finalRef = factor.ref.current;
                    let finalRef2 = factor.ref2.current;

                    finalRef.classList.remove("hidden");
                    finalRef.classList.add("flex");
                    finalRef2?.classList?.remove("flex");
                    finalRef2?.classList?.add("hidden");
                    let x = getX(diamondTextRef3.current) - getX(finalRef);
                    let y = getY(diamondTextRef3.current) - getY(finalRef);
                    finalRef.style.transform = `translate(${x}px,${y}px)`;
                    finalRef.style.transition = "transform";
                    setTimerId4(
                      setTimeout(() => {
                        finalRef.style.transform = `translate(${0}px,${0}px)`;
                        finalRef.style.transition = "transform 1s";
                      }, 200)
                    );
                    setTimerId5(
                      setTimeout(() => {
                        setSubmitDisabled(false);
                        factorButtonsEvent({
                          single: false,
                          type: "",
                          add: false,
                        });
                      }, 1000)
                    );

                    emptyTextNode();
                    nodeTransform({ distance: 0 });
                  }, 1000)
                );
              }, 200)
            );
          }
        }
      }, 1200)
    );
  };

  const checkGcf = ({ type, number, ref, diamondRef }) => {
    factorButtonsEvent({ single: true, type, add: false });
    const stopRef =
      type === "secondTree" ? diamondTextRef2.current : diamondTextRef1.current;
    const stopRef2 =
      type === "secondTree" ? diamondTextRef4.current : diamondTextRef3.current;
    flyingNode({
      startRef: ref.current,
      stopRef,
      stopRef2,
      number,
    });
    diamondRef.current.classList.add("opacity-100");
    const temp = { ...finalFactors };
    temp[type].map((f, index) => {
      f.disable = true;
      f.picked = f.ref.current === ref.current;
      return f;
    });
    setFinalFactors(temp);
  };

  useEffect(() => {
    handleNext();
    levelRef.current = challengeLevel;
  }, [challengeLevel, handleNext]);

  useEffect(() => {
    if (next) {
      clearTimeout(timerId1);
      clearTimeout(timerId2);
      clearTimeout(timerId3);
      clearTimeout(timerId4);
      clearTimeout(timerId5);
      clearTimeout(timerId6);
      clearTimeout(timerId7);
      clearTimeout(timerId8);
      clearTimeout(timerId9);
      clearTimeout(timerId10);

      setFirstNumber(
        getNumber({
          max:
            challengeLevel === 0
              ? 21
              : challengeLevel === 1
              ? 51
              : challengeLevel === 2 && 101,
          min:
            challengeLevel === 0
              ? 4
              : challengeLevel === 1
              ? 20
              : challengeLevel === 2 && 50,
        })
      );
      setNext(false);
    }
  }, [
    next,
    getNumber,
    challengeLevel,
    timerId1,
    timerId2,
    timerId3,
    timerId4,
    timerId5,
    timerId6,
    timerId7,
    timerId8,
    timerId9,
    timerId10,
  ]);

  useEffect(() => {
    if (firstMaxFactors.length > 0 && width) {
      growSvg({
        maxFactors: firstMaxFactors,
        numOfRows: firstMaxRows,
        setSvgDim: setFirstSvgDim,
        width,
        height,
      });
    }
  }, [firstMaxFactors, firstMaxRows, width, height]);

  useEffect(() => {
    if (secondMaxFactors.length > 0 && width) {
      growSvg({
        maxFactors: secondMaxFactors,
        numOfRows: secondMaxRows,
        setSvgDim: setSecondSvgDim,
        width,
        height,
      });
    }
  }, [secondMaxFactors, secondMaxRows, width, height]);

  useEffect(() => {
    if (firstNumber) {
      const dn = createTemplate();
      setFirstNumberTree([
        {
          ...dn,
          number: firstNumber,
        },
      ]);
      const fmf = findMaxFactors(firstNumber);
      setFirstMaxFactors(fmf);
      let sn = getNumber({
        max:
          challengeLevel === 0
            ? 21
            : challengeLevel === 1
            ? 51
            : challengeLevel === 2 && 101,
        min: 4,
      });
      const gcfGtOne = (sn) => {
        const smf = findMaxFactors(sn);
        const hasCommon = fmf.some((f) => smf.includes(f));
        if (hasCommon) setSecondMaxFactors(smf);
        return !hasCommon;
      };
      while (sn === firstNumber || gcfGtOne(sn)) {
        sn = getNumber({
          max:
            challengeLevel === 0
              ? 21
              : challengeLevel === 1
              ? 51
              : challengeLevel === 2 && 101,
          min: 4,
        });
      }
      setSecondNumber(sn);
    }
  }, [
    firstNumber,
    getNumber,
    findMaxFactors,
    challengeLevel,
    dataNode,
    createTemplate,
  ]);

  useEffect(() => {
    resetAnimate({ numberTreeType: firstNumberTree });
  }, [firstNumberTree]);

  useEffect(() => {
    partyItems.length === 2 &&
      currentNode &&
      moveFocus({
        numberTree: firstNumberTree,
        refLocation: currentNode,
      });
  }, [currentNode, firstNumberTree, partyItems]);

  useEffect(() => {
    if (firstTreeComplete && currentNode) {
      moveFocus({
        numberTree: secondNumberTree,
        refLocation: currentNode,
      });
    }
  }, [currentNode, secondNumberTree, firstTreeComplete]);

  useEffect(() => {
    resetAnimate({ numberTreeType: secondNumberTree });
  }, [secondNumberTree]);

  useEffect(() => {
    if (secondNumber) {
      const dn = createTemplate();
      setSecondNumberTree([
        {
          ...dn,
          number: secondNumber,
        },
      ]);
    }
  }, [secondNumber, dataNode, createTemplate]);

  useEffect(() => {
    if (firstSvgDim) {
      assignCoords({ setNumberTree: setFirstNumberTree, svgDim: firstSvgDim });
    }
  }, [firstSvgDim]);

  useEffect(() => {
    if (secondSvgDim) {
      assignCoords({
        setNumberTree: setSecondNumberTree,
        svgDim: secondSvgDim,
      });
    }
  }, [secondSvgDim]);

  const treeComplete = useCallback(({ tree, setComplete, number }) => {
    let final = 1;
    tree.forEach((f) => {
      final = final * f.number;
      setComplete(final === number);
    });
  }, []);

  useEffect(() => {
    treeComplete({
      tree: finalFactors.firstTree,
      setComplete: setFirstTreeComplete,
      number: firstNumber,
    });
  }, [finalFactors.firstTree, firstNumber, treeComplete]);

  useEffect(() => {
    treeComplete({
      tree: finalFactors.secondTree,
      setComplete: setSecondTreeComplete,
      number: secondNumber,
    });
  }, [finalFactors.secondTree, secondNumber, treeComplete]);

  useEffect(() => {
    firstTreeComplete && setCurrentNode({ row: 0, pos: 1 });
    if (firstTreeComplete && secondTreeComplete) {
      setFinalFactors((finalFactors) => {
        finalFactors.firstTree.map((f) => (f.disable = false));
        finalFactors.secondTree.map((f) => (f.disable = false));
        return { ...finalFactors };
      });
    }
  }, [firstTreeComplete, secondTreeComplete]);

  useEffect(() => {
    let fmf =
      firstMaxFactors.length <= secondMaxFactors.length
        ? [...firstMaxFactors]
        : [...secondMaxFactors];
    let smf =
      firstMaxFactors.length > secondMaxFactors.length
        ? [...firstMaxFactors]
        : [...secondMaxFactors];

    let common = [];
    while (fmf.length) {
      const smfIndex = smf.indexOf(fmf[0]);
      if (smfIndex > -1) {
        common.push({
          number: fmf[0],
          show: false,
          ref: createRef(),
          ref2: createRef(),
        });

        smf = smf.filter((s, index) => index !== smfIndex);
      }
      fmf.shift();
    }
    setGcf(orderBy([...common], ["number"], ["asc"]));
    setGcf([...common]);
  }, [firstMaxFactors, secondMaxFactors]);

  useEffect(() => {
    let picked = [
      ...finalFactors.firstTree.filter((f) => f.picked),
      ...finalFactors.secondTree.filter((f) => f.picked),
    ];
    if (picked.length === 2) {
      if (picked[0].number === picked[1].number) {
        setFinalFactors((finalFactors) => {
          for (const [key] of Object.entries(finalFactors)) {
            finalFactors[key].map((f) => {
              if (f.picked) {
                f.matched = true;
                f.disable = true;
                f.picked = false;
              } else if (!f.matched) {
                f.matched = false;
                f.picked = false;
                f.disable = false;
              }
              return f;
            });
          }
          return { ...finalFactors };
        });
      } else {
        setFinalFactors((finalFactors) => {
          for (const [key] of Object.entries(finalFactors)) {
            finalFactors[key].map((f) => {
              if (!f.matched) {
                f.diamondRef.current.classList.remove("opacity-100");
                f.diamondRef.current.classList.add("opacity-0");
                f.matched = false;
                f.disable = false;
                f.picked = false;
              }
              return f;
            });
          }
          setErrorModal(true);

          setMessage({
            message: (
              <div className="flex flex-wrap place-content-center">
                <div className="self-center text-center">
                  Not a common factor
                </div>
              </div>
            ),
            bgColor: "bg-yellow-600",
          });
          return { ...finalFactors };
        });
      }
    }
  }, [finalFactors]);

  const checkSolution = () => {
    let gcfArr = [];
    gcf.map(({ number }) => (gcfArr = [...gcfArr, number]));
    const equals = gcf.every((f) => f.show);
    if (equals) {
      setShowSolution(true);
      setSubmitDisabled(true);
      setTimerId10(setTimeout(() => setShowConfettiEmo(true), [1500]));
      const sol = 1;
      setSolution(gcfArr.reduce((a, c) => a * c, sol));
      setFinalFactors((finalFactors) => {
        for (const [key] of Object.entries(finalFactors)) {
          finalFactors[key].map((f) => {
            if (!f.matched) {
              f.diamondRef.current.classList.remove("opacity-100");
              f.diamondRef.current.classList.add("opacity-0");
            }
            f.disable = true;
            f.picked = false;
            return f;
          });
        }
        return { ...finalFactors };
      });
      setSolved((solved) => {
        solved.overAll = solved.overAll + 1;
        solved[`level${levelRef.current + 1}`] =
          solved[`level${levelRef.current + 1}`] + 1;
        return solved;
      });
      setScore((score) => {
        score[`level${levelRef.current + 1}`] =
          score[`level${levelRef.current + 1}`] + 1;
        score.overAll = score.overAll + 1;
        return score;
      });
      saveScores({ solved, score, type: "greatestCommonFactor" });
    } else {
      setErrorModal(true);
      setMessage({
        message: (
          <div className="flex flex-wrap place-content-center">
            <div className="self-center text-center">
              Try again, there is at least one more common factor!
            </div>
          </div>
        ),
        bgColor: "bg-yellow-600",
      });
    }
  };

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  return [
    handleNext,
    firstNumber,
    firstNumberTree,
    secondNumber,
    secondNumberTree,
    checkFactorInput,
    errorModal,
    setErrorModal,
    message,
    checkFactor,
    firstSvgDim,
    secondSvgDim,
    inputChange,
    firstTreeComplete,
    secondTreeComplete,
    finalFactors,
    checkGcf,
    gcf,
    showSolution,
    solution,
    showConfettiEmo,
    setShowConfettiEmo,
    scorePct,
    checkSolution,
    partyItems,
    setPartyItems,
    showInstructions,
    setShowInstructions,
    diamondTextRef1,
    diamondTextRef2,
    diamondTextRef3,
    diamondTextRef4,
    diamondRef1,
    diamondRef2,
    gcfRef,
    submitDisabled,
  ];
};

export default useGetGcfEquation;
