import { useState, useEffect, useRef, useMemo, useCallback } from "react";
const keys = require("../keys.js");

const useLevelScores = ({ authData, getAuthToken, loggedOut }) => {
  const [score, setScore] = useState("");
  const [solved, setSolved] = useState("");
  const [challengeLevel, setChallengeLevel] = useState(0);
  const [scoreLN, setScoreLN] = useState("");
  const [solvedLN, setSolvedLN] = useState("");
  const [challengeLevelLN, setChallengeLevelLN] = useState(0);
  const [scoreK, setScoreK] = useState("");
  const [solvedK, setSolvedK] = useState("");
  const [challengeLevelK, setChallengeLevelK] = useState(0);
  const [scoreA, setScoreA] = useState("");
  const [solvedA, setSolvedA] = useState("");
  const [challengeLevelA, setChallengeLevelA] = useState(0);
  const [scoreFr, setScoreFr] = useState("");
  const [solvedFr, setSolvedFr] = useState("");
  const [challengeLevelFr, setChallengeLevelFr] = useState(0);
  const [scoreGcf, setScoreGcf] = useState("");
  const [solvedGcf, setSolvedGcf] = useState("");
  const [challengeLevelGcf, setChallengeLevelGcf] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [tabSelect, setTabSelect] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const buttonRef = useRef(null);
  const [gameDetails, setGameDetails] = useState([
    {
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    },
    () => {},
    {
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    },
    () => {},
    {
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    },
    () => {},
  ]);
  const [saveDataFail, setSaveDataFail] = useState(false);
  const [showStudentsData, setShowStudentsData] = useState(false);
  const games = useMemo(
    () => [
      "Polygon Side Length Challenge",
      "Number Line Fraction Division Challenge",
      "Find the Proportionality Constant",
      "Measure the Angle Using Algebra",
      "Combine Fraction Bars to equal 1",
      "Find the Greatest Common Factor",
    ],
    []
  );

  const resetScores = () => {
    setScore({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolved({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setScoreLN({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolvedLN({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setScoreK({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolvedK({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setScoreA({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolvedA({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setScoreFr({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolvedFr({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setScoreGcf({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
    setSolvedGcf({
      level1: 0,
      level2: 0,
      level3: 0,
      overAll: 0,
    });
  };

  const getScores = useCallback(async () => {
    if (authData?.email) {
      try {
        const postData = await getAuthToken();
        postData.body = JSON.stringify({ email: authData.email });
        const request = new Request(
          keys.cloudFunctions.getUserScores,
          postData
        );
        const response = await fetch(request);
        const gameScores = await response.json();
        if (gameScores) {
          const {
            polygon,
            numberLine,
            kConstant,
            angles,
            fractionBars,
            greatestCommonFactor,
          } = gameScores.games;
          if (polygon) {
            setScore(polygon?.score);
            setSolved(polygon?.solved);
          }
          if (numberLine) {
            setScoreLN(numberLine?.score);
            setSolvedLN(numberLine?.solved);
          }
          if (kConstant) {
            setScoreK(kConstant?.score);
            setSolvedK(kConstant?.solved);
          }
          if (angles) {
            setScoreA(angles?.score);
            setSolvedA(angles?.solved);
          }
          if (fractionBars) {
            setScoreFr(fractionBars?.score);
            setSolvedFr(fractionBars?.solved);
          }
          if (greatestCommonFactor) {
            setScoreGcf(greatestCommonFactor?.score);
            setSolvedGcf(greatestCommonFactor?.solved);
          }
        }
      } catch (error) {}
    } else {
      resetScores();
    }
  }, [getAuthToken, authData.email]);

  const saveScores = useCallback(
    async ({ solved, score, type }) => {
      setSaveDataFail(false);
      if (authData?.email) {
        try {
          const year = new Date().getFullYear();
          const mth = (new Date().getMonth() + 1).toString();
          const mo = mth.length === 1 ? `0${mth}` : mth;
          const dte = new Date().getDate().toString();
          const dt = dte.length === 1 ? `0${dte}` : dte;
          const unixDateStamp = Math.floor(
            new Date(`${year}-${mo}-${dt} 00:00:00.000`).getTime() / 1000
          );
          const postData = await getAuthToken();
          postData.body = JSON.stringify({
            email: authData.email,
            games: {
              [type]: { solved, score, date: unixDateStamp },
              currentGame: type,
              currentLevel: `level${challengeLevel + 1}`,
            },
          });
          const request = new Request(
            keys.cloudFunctions.saveUserScores,
            postData
          );
          const response = await fetch(request);
          const saveGamesResult = await response.json();
          if (!saveGamesResult?.success) {
            setSaveDataFail(true);
          }
          getScores();
        } catch (error) {
          setSaveDataFail(true);
          getScores();
        }
      }
    },
    [getAuthToken, authData.email, challengeLevel, getScores]
  );

  useEffect(() => {
    let timerId;
    if (buttonRef.current) {
      timerId = setTimeout(() => buttonRef.current.focus(), [100]);
    }
    if (tabSelect !== null) {
      setCurrentTab(tabSelect);
    }

    switch (tabSelect) {
      case 0:
        setGameDetails([
          score,
          setScore,
          solved,
          setSolved,
          challengeLevel,
          setChallengeLevel,
        ]);
        break;
      case 1:
        setGameDetails([
          scoreLN,
          setScoreLN,
          solvedLN,
          setSolvedLN,
          challengeLevelLN,
          setChallengeLevelLN,
        ]);
        break;
      case 2:
        setGameDetails([
          scoreK,
          setScoreK,
          solvedK,
          setSolvedK,
          challengeLevelK,
          setChallengeLevelK,
        ]);
        break;
      case 3:
        setGameDetails([
          scoreA,
          setScoreA,
          solvedA,
          setSolvedA,
          challengeLevelA,
          setChallengeLevelA,
        ]);
        break;
      case 4:
        setGameDetails([
          scoreFr,
          setScoreFr,
          solvedFr,
          setSolvedFr,
          challengeLevelFr,
          setChallengeLevelFr,
        ]);
        break;
      case 5:
        setGameDetails([
          scoreGcf,
          setScoreGcf,
          solvedGcf,
          setSolvedGcf,
          challengeLevelGcf,
          setChallengeLevelGcf,
        ]);
        break;
      default:
        setGameDetails([
          {
            level1: 0,
            level2: 0,
            level3: 0,
            overAll: 0,
          },
          () => {},
          {
            level1: 0,
            level2: 0,
            level3: 0,
            overAll: 0,
          },
          () => {},
          0,
          () => {},
        ]);
    }
    return () => clearTimeout(timerId);
  }, [
    tabSelect,
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    scoreLN,
    setScoreLN,
    solvedLN,
    setSolvedLN,
    challengeLevelLN,
    setChallengeLevelLN,
    scoreK,
    setScoreK,
    solvedK,
    setSolvedK,
    challengeLevelK,
    setChallengeLevelK,
    scoreA,
    setScoreA,
    solvedA,
    setSolvedA,
    challengeLevelA,
    setChallengeLevelA,
    scoreFr,
    setScoreFr,
    solvedFr,
    setSolvedFr,
    challengeLevelFr,
    setChallengeLevelFr,
    scoreGcf,
    setScoreGcf,
    solvedGcf,
    setSolvedGcf,
    challengeLevelGcf,
    setChallengeLevelGcf,
  ]);

  useEffect(() => {
    getScores();
  }, [getScores]);

  useEffect(() => {
    if (loggedOut) {
      resetScores();
    }
  }, [loggedOut]);

  return [
    ...gameDetails,
    showTabs,
    setShowTabs,
    tabSelect,
    setTabSelect,
    buttonRef,
    currentTab,
    games,
    saveScores,
    saveDataFail,
    setSaveDataFail,
    showStudentsData,
    setShowStudentsData,
  ];
};
export default useLevelScores;
