import { useRef, useEffect } from "react";

const GcfCheck = ({
  gcf,
  showSolution,
  checkSolution,
  solution,
  gcfRef,
  submitDisabled,
}) => {
  const checkRef = useRef(null);

  useEffect(() => {
    if (!submitDisabled) checkRef.current.focus();
  }, [submitDisabled]);

  return (
    <div className="p-1 w-1/4 max-[1024px]:w-full self-center flex flex-col place-content-center border border-black">
      <div
        className="self-center flex flex-wrap place-content-center"
        ref={gcfRef}
      >
        GCF =&nbsp;
        {gcf.length === 0
          ? "?"
          : gcf.map(({ number, show, ref, ref2 }, index) => (
              <div
                key={index}
                className="self-center flex flex-wrap place-centent-center"
              >
                {!show && (
                  <p
                    ref={ref2}
                    className={`self-center text-[#800080] ${
                      !show ? "flex" : "hidden"
                    }`}
                  >
                    ?
                  </p>
                )}
                <p
                  ref={ref}
                  className={`self-center text-[#800080] ${
                    show ? "flex" : "hidden"
                  }`}
                >
                  {number}
                </p>
                {index < gcf.length - 1 && (
                  <p className={`self-center text-[#800080] `}>&nbsp;*&nbsp;</p>
                )}
              </div>
            ))}
        &nbsp;{showSolution && gcf.length > 1 && `= ${solution}`}
      </div>
      <div className="self-center">
        <button
          ref={checkRef}
          disabled={submitDisabled}
          onClick={checkSolution}
          className={`${
            submitDisabled
              ? "text-slate-400"
              : "text-blue-600 hover:text-green-600 hover:outline-none focus:text-green-600 focus:outline-none"
          } `}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default GcfCheck;
