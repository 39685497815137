import { useSchoolActions } from "../../hooks";
import MessageModal from "../MessageModal/MessageModal";
import Skeleton from "../Loader/Skeleton";
import Form from "./Form";

const SchoolRegistration = ({
  getAuthToken,
  authData,
  setShowSpinner,
  showSchoolReg,
  setShowSchoolReg,
}) => {
  const [
    saveSchool,
    saveSchoolFail,
    setSaveSchoolFail,
    schoolNames,
    saveFailMessage,
    completeFetch,
    modalRef,
    formReady,
    userType,
    disbledRadio,
    setUserType,
    school,
    setSchool,
    saveRef,
  ] = useSchoolActions({
    getAuthToken,
    authData,
    setShowSpinner,
    showSchoolReg,
    setShowSchoolReg,
  });

  return (
    <div ref={modalRef}>
      <div className="bg-gray-900 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0">
        <div className="overflow-auto max-[1024px]:w-11/12 w-2/3 h-3/4 text-base max-[320px]:text-xs max-[640px]:text-sm z-50 bg-teal-100 border-solid border-slate-400 border-2 rounded p-1 rounded-2xl text-center flex flex-col place-content-center">
          <MessageModal
            openModal={saveSchoolFail}
            closeModal={() => setSaveSchoolFail(false)}
            message={{
              message: (
                <div className="flex flex-row place-content-center">
                  <p className="self-center text-center font-bold">
                    {saveFailMessage}
                  </p>
                </div>
              ),
              bgColor: "bg-orange-600",
            }}
          />
          {completeFetch && formReady ? (
            <Form
              userType={userType}
              disbledRadio={disbledRadio}
              setUserType={setUserType}
              school={school}
              setSchool={setSchool}
              saveSchool={saveSchool}
              schoolNames={schoolNames}
              setShowSchoolReg={setShowSchoolReg}
              saveRef={saveRef}
            />
          ) : (
            <Skeleton
              times={4}
              cn={"w-full h-1/4 bg-gray-200"}
              transitionColor={"from-gray-200 via-teal-200 to-gray-200"}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SchoolRegistration;
