import { useState, useEffect, useRef } from "react";
import orderBy from "lodash.orderby";
import { TiArrowUpThick } from "react-icons/ti";

const FactorButtons = ({
  factors,
  cn,
  mainNumber,
  checkGcf,
  type,
  firstTreeComplete,
  secondTreeComplete,
  treeComplete,
}) => {
  const [treeSolved, setTreeSolved] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const arrowRef = useRef(null);

  useEffect(() => {
    setTreeSolved(firstTreeComplete && secondTreeComplete);
  }, [firstTreeComplete, secondTreeComplete]);

  useEffect(() => {
    setShowArrow(treeSolved);
  }, [treeSolved]);

  useEffect(() => {
    if (arrowRef?.current && showArrow)
      arrowRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [showArrow]);

  return (
    <div
      id={`${type}FactorButtons`}
      ref={type === "firstTree" ? arrowRef : null}
      className="w-1/4 max-[1024px]:w-full self-center relative"
    >
      <div className="border border-black bg-red-100 self-center flex flex-wrap place-content-center">
        <div className="self-center flex flex-col place-content-center">
          {treeSolved && (
            <div className="self-center text-xs text-purple-400">
              Select all common factors here that match a common factor in the
              other value’s prime factorization.
            </div>
          )}
          <div className="self-center flex flex-wrap place-content-center w-full">
            <div className="self-center">{mainNumber} =&nbsp;</div>
            <div className="self-center flex flex-wrap place-conten-center">
              {orderBy([...factors], ["number"], ["asc"]).map(
                (
                  { number, ref, disable, picked, matched, diamondRef },
                  index
                ) => (
                  <div
                    key={index}
                    className="appear self-center flex flex-row place-content-center"
                  >
                    <button
                      ref={ref}
                      disabled={disable}
                      onClick={() => {
                        setShowArrow(false);
                        checkGcf({ type, number: number, ref, diamondRef });
                      }}
                      className={`self-center border ${!disable && cn} ${
                        (picked || matched) && "bg-yellow-200"
                      } max-[1024px]:h-5 max-[1024px]:w-5 h-7 w-7 rounded-full`}
                    >
                      {number}
                    </button>
                    {(!treeComplete || index < factors.length - 1) && (
                      <div className="self-center mt-1">&nbsp;*&nbsp;</div>
                    )}
                  </div>
                )
              )}
              {!treeComplete && <div className="self-center">&nbsp;?</div>}
            </div>
          </div>
        </div>
      </div>

      <TiArrowUpThick
        className={`${
          type === "firstTree" ? "text-blue-600" : "text-red-600"
        } ${
          showArrow
            ? "inline animate-bounce w-7 h-7 text-sm rounded-full absolute left-1/2 bg-yellow-300"
            : "hidden"
        }`}
      />
    </div>
  );
};

export default FactorButtons;
